* {
    box-sizing: border-box;
}

body,
html,
#app-container {
    height: 100%;
}

.ant-btn-primary {
    background-color: #EC861E;
    border-color: #EC861E;
}

.ant-btn-primary:hover {
    background-color: #EC861E;
    border-color: #EC861E;
}

.companyLogo {
    width: 180px;
}

@media(max-width: 350px) {
    .companyLogo {
        width: 100px;
    }
}

/* Reduce navbar width to counteract the larger logo */
.navbar {
    width: calc(100% - 420px);
}